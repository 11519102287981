@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overscroll-behavior: none;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
#root,
.App,
.carousel {
  overscroll-behavior: contain;
}
