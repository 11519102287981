.logo {
   transition: fill 2s ease 0.5s;
}
.header-button {
   transition: all 2s ease 0.5s;
}
.light {
   fill: theme('palette.grey-05');
}
.dark {
   fill: theme('palette.grey-90');
}