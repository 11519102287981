.carousel {
  overflow: hidden;
  position: relative;
  height: 100.1vh;
}

.inner {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 0.5s;
}

.carousel-item {
  width: 100%;
  height: 0vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  overflow: hidden;
  transition: height 0.5s;
}

.carousel-item.active {
  top: unset;
  bottom: 0;
  height: 100%;
  z-index: 0;
  transition: height 0s 0s;
}

.carousel-item.active ~ .carousel-item {
  top: unset;
  bottom: 0;
}
