.indicators {
  transform: rotate(90deg);
  bottom: 50%;
}

.indicators > button,
.pager-holder > button {
  margin: 0.5rem;
  transition: opacity 0.5s, border-color 0.5s, background-color 0.5s;
}

.pager-holder > button:focus {
  outline-width: 0;
}

.pager-holder > button.active {
  position: relative;
}

.pager-dot {
  margin: 0.5rem;
  transition: left 0.5s, width 0.5s, opacity 0.5s, border-color 0.5s,
    background-color 0.5s;
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
}

.caret-icon.dark {
  fill: theme("palette.grey-05");
}

.caret-icon.dark {
  fill: theme("palette.grey-90");
}
