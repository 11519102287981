:root {
  --translate-in: translateY(-25%);
  --translate-in2: translateY(25%);
  --translate-out: translateY(-100%);
  --translate-out2: translateY(100%);
}
.tile-gallery {
  right: 4.5rem;
}
.trans-dn,
.trans-up {
  transition: transform 2s cubic-bezier(0.16, 1, 0.3, 1), opacity 0.5s;
}
.custom-center {
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.gallery-card {
  padding-bottom: 130%;
}
.carousel-item .trans-up {
  transform: var(--translate-out); 
}
.carousel-item .trans-dn {
  transform: var(--translate-out2);
}
.carousel-item.active .trans-up {
  transform: var(--translate-in);
}
.carousel-item.active .trans-dn {
  transform: var(--translate-in2);
}
@media (min-width: 640px) {
  .carousel-item .lg\:trans-up {
    transform: var(--translate-out);
  }
  .carousel-item .lg\:trans-dn {
    transform: var(--translate-out2);
  }
  .carousel-item.active .lg\:trans-up {
    transform: var(--translate-in);
  }
  .carousel-item.active .lg\:trans-dn {
    transform: var(--translate-in2);
  }
}
@media (min-width: 768px) {
  .tile-gallery {
    right: 0;
  }
}