.custom-underline {
  position: relative;
}

.custom-underline::after {
  content: "";
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
  width: 0%;
  position: absolute;
  bottom: 1px;
  right: 0;
  z-index: -1;
  transition: width 1s cubic-bezier(0.16, 1, 0.3, 1);
}

.custom-underline:hover::after,
.custom-underline:active:after,
.custom-underline:focus:after {
  left: 0;
  right: unset;
  width: 100%;
}

.custom-underline::before {
  content: "";
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  position: absolute;
  bottom: 1px;
  right: 0;
  z-index: -1;
}
.theme-light .custom-underline::after
,.theme-light .custom-underline::before {
  border-bottom: 1px solid theme('palette.grey-05');
}