:root {
    --gradient: linear-gradient(
       45deg,
       theme('palette.red-50'),
       theme('palette.purple-50'),
       theme('palette.blue-50'),
       theme('palette.green-50')
       );
 }
@keyframes blur {
   0% { -webkit-filter: blur(100px);}
   100% {-webkit-filter: blur(0px);}
}
.animated_blur {
   -webkit-filter: blur(100px);
   animation-name: blur;
   animation-duration: 1.2s;
   animation-timing-function: cubic-bezier(0.12, 0, 0.39, 0);;
   animation-iteration-count:1;
   animation-fill-mode: both;
   
}
.text-bg {
   font-size: 16vw;
   width: 84vw;
}
.text-bg-xl {
   font-size: 42vw;
   width: 100vw;
}
.learn-more .down-arrow g#masked-down-arrow-group
, .learn-more .down-arrow g#down-arrow-group{
   animation: none;
   transition: opacity 0.8s cubic-bezier(0.16, 1, 0.3, 1);
}
.learn-more .down-arrow g#masked-down-arrow-group {
   opacity: 0;
}
.learn-more .down-arrow g#down-arrow-group {
   opacity: 1;
}
.learn-more:hover .down-arrow {
   animation: bounce 2s ease-in-out infinite;
}

.learn-more:hover .down-arrow g#masked-down-arrow-group {
   opacity: 1;
}
.learn-more:hover .down-arrow g#down-arrow-group {
   opacity: 0;
}
.animate-bg {
   background-image: var(--gradient);
   background-size: 600vw;
   animation: bg-animation 20s infinite alternate cubic-bezier(0.83, 0, 0.17, 1) 3s;
}
@keyframes bounce {
   0%, 20%, 40%, 60%, 80%, 100% {-webkit-transform: translateY(0);}
   50% {-webkit-transform: translateY(8px);}
   58% {-webkit-transform: translateY(-2px);}
} 
@keyframes bg-animation {
   0% {
      background-position: left;
   }
   100% {
      background-position: right;
   }
}
@media (min-width: 640px) { 
   .quote {
      width: 550px;
   }  
   .centered-content {
      height: 37vw;
   }
}